<template>
  <div class="page-contact-container">
    <div class="contact__wrapper">
      <div class="contact__main-body">
        <div class="contact__swiper scrollObj">
          <swiper
            class="contact__swiper-wrapper"
            :options="swiperOptions"
            v-if="lists"
          >
            <swiper-slide
              class="swiper__slide"
              v-for="(item, index) in lists"
              :key="index"
            >
              <img
                class="preload"
                :data-src="$isMobile ? item.src.mobile : item.src.pc"
                :src="$isMobile ? item.src.mobile : item.src.pc"
                alt=""
              />
            </swiper-slide>
          </swiper>
        </div>
        <div class="contact__content">
          <div class="content__wrapper">
            <div class="contact__title scrollObj">contact</div>
            <div class="contact__main-content">
              <div class="content__group">
                <div class="group__item scrollObj">
                  <p class="title">address</p>
                  <p class="content">
                    闵行区先锋路25号
                    <br />中国上海
                  </p>
                </div>
                <div class="group__item scrollObj">
                  <p class="title">official contact</p>
                  <p class="content">
                    电话 021-62951811<br />邮箱 brand@chicforgo.com
                  </p>
                </div>
              </div>
              <div class="content__group">
                <div class="group__item scrollObj">
                  <p class="title">working time</p>
                  <p class="content">周一至周五<br />上午9：30 - 下午5：45</p>
                </div>
                <div class="group__item scrollObj" v-if="!$isMobile">
                  <p class="title">wechat</p>
                  <img class="qr" src="@/assets/images/contact-qr.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { TweenMax } from "gsap";
import { mapMutations } from "vuex";
export default {
  name: "contact",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOptions: {
        autoplay: {
          delay: 5000
        },
        loop: true,
        speed: 1000,
        grabCursor: false,
        watchSlidesProgress: true,
        mousewheelControl: true,
        keyboardControl: true,
        on: {
          transitionStart() {
            const swiper = this;
            const duration =
              swiper.passedParams.autoplay.delay + swiper.passedParams.speed;
            const $active = swiper.$wrapperEl["0"].querySelector(
              ".swiper-slide-active"
            );
            TweenMax.fromTo(
              $active,
              duration / 1000,
              {
                scale: 1.07
              },
              {
                scale: 1
              }
            );
          }
        }
      },
      lists: null
    };
  },
  watch: {
    $route: {
      handler: "init",
      immediate: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      document.body.classList.add("contact");
    });
  },
  methods: {
    ...mapMutations(["PAGE_LOADING", "PAGE_LOADED"]),
    init() {
      this.PAGE_LOADING();
      this.getLists().then(res => {
        this.lists = res.data.lists;
        this.PAGE_LOADED();
      });
    },
    getLists() {
      return this.$request.get("/api/scroll/contact");
    }
  }
};
</script>
